<template>
  <div class="content">
    <div class="bg-1"></div>
    <div class="card card1">
      <div class="companyname">主场服务在线申报平台</div>
      <div class="title"></div>
      <div class="tabs">
        <div
          class="item"
          @click="tabsactive = true"
          :class="{ itemactive: tabsactive }"
        >
          登陆入口
        </div>
        <div
          class="item"
          @click="tabsactive = false"
          :class="{ itemactive: !tabsactive }"
        >
          注册入口
        </div>
      </div>
      <div class="form" v-if="tabsactive">
        <div class="item">
          <i class="el-icon-user"></i>
          <input
            class="input"
            placeholder="手机号/报名号登录"
            maxlength="18"
            v-model="phone"
          />
        </div>
        <div class="item" v-if="!codeLoginState">
          <i class="el-icon-lock"></i>
          <input class="input" placeholder="登陆密码" v-model="logPass" />
        </div>
        <div class="item" v-else>
          <i class="el-icon-mobile-phone"></i>
          <input class="input" placeholder="短信验证码" v-model="code" />
          <div class="item-btn" @click="getCodeFun(1)">{{ codeTip }}</div>
        </div>
        <!-- <div
          style="text-align: center; color: #2c9dfb"
          @click="toCodeLogin"
          v-if="!codeLoginState"
        >
          验证码登录
        </div> -->
        <!-- <div
          style="text-align: center; color: #2c9dfb"
          @click="toCodeLogin"
          v-else
        >
          密码登录
        </div> -->
        <el-button class="btn" @click="sub" type="primary" round
          >登陆</el-button
        >
      </div>
      <div class="form" v-if="!tabsactive">
        <div class="item">
          <i class="el-icon-user"></i>
          <input
            class="input"
            placeholder="输入11位手机号"
            type="tel"
            maxlength="11"
            v-model="phone"
            @blur="phoneBlur"
          />
        </div>
        <div class="item">
          <i class="el-icon-lock"></i>
          <input
            style="width: 100%"
            class="input"
            placeholder="输入密码(8-15位字母和数字组合)"
            v-model="password"
          />
        </div>
        <div class="item">
          <i class="el-icon-mobile-phone"></i>
          <input class="input" placeholder="短信验证码" v-model="code1" />
          <div class="item-btn" @click="getCodeFun(2)">{{ codeTip1 }}</div>
        </div>
        <div class="item">
          <i class="el-icon-office-building"></i>
          <input
            class="input"
            placeholder="输入公司名称"
            v-model="comparename"
          />
        </div>
        <el-button class="btn" type="primary" round @click="registerFun"
          >注册</el-button
        >
        <div class="text">
          <span>已有账号？</span>
          <span class="span">立即登录</span>
        </div>
      </div>
      <div class="suggest">
        建议使用新版本：谷歌，火狐，360极速版，QQ等浏览器
      </div>
      <!-- <div class="texts">
        <span>使用手册</span>
        <span>忘记密码？</span>
      </div> -->
    </div>
    <!-- <img class="logo" src="@/assets/logo2.jpg" alt="" /> -->
  </div>
</template>
<script>
import { getCode, register, Login, codeLogin } from "../utils/api/index";
import { getUserInfo } from "../utils/api/user";
export default {
  data() {
    return {
      tabsactive: true,
      phone: "",
      code: "",
      code1: "",
      password: "",
      comparename: "",
      logPass: "",
      phoneReg: /^1[3456789]\d{9}$/,
      passwordReg: /^(?=.*[a-zA-Z])(?=.*\d)[a-zA-Z\d]{8,15}$/,
      codeLoginState: false,
      codeTip: "获取验证码",
      codeTip1: "获取验证码",
      codeTime: 60,
      codeTime1: 60,
      interval: null,
      interval1: null,
    };
  },
  computed: {},
  mounted() {
    localStorage.clear();
  },
  destroyed() {
    clearInterval(this.interval);
    clearInterval(this.interval1);
  },
  methods: {
    toCodeLogin() {
      this.codeLoginState = !this.codeLoginState;
    },
    phoneBlur() {
      if (!this.phoneReg.test(this.phone)) {
        this.$message.error("手机号格式不正确");
        this.phone = "";
      }
    },
    async sub() {
      if (this.codeLoginState) {
        let params = {
          phone: this.phone,
          verifyCode: this.code,
        };
        let loginRes = await codeLogin(params);
        if (loginRes.code !== 200) {
          this.$message.error(loginRes.message);
          return;
        }
        localStorage.setItem("token", loginRes.data);
        let userInfo = await getUserInfo();
        localStorage.setItem("userInfos", JSON.stringify(userInfo.data));
        setTimeout(() => {
          // this.$router.push("/index/myinfo")
          this.$router.push("/index");
        }, 80);
      } else {
        let params = {
          phone: this.phone,
          userPass: this.logPass,
        };
        let loginRes = await Login(params);
        if (loginRes.code !== 200) {
          this.$message.error(loginRes.message);
          return;
        }
        localStorage.setItem("token", loginRes.data);
        let userInfo = await getUserInfo();
        //userInfo.istype  1 搭建商 2展商
        console.log(userInfo);
        localStorage.setItem("userInfos", JSON.stringify(userInfo.data));
        setTimeout(() => {
          // this.$router.push("/index/myinfo")
          this.$router.push("/index");
        }, 80);
      }
    },
    codeCount(type) {
      if (type == 1) {
        this.interval = setInterval(() => {
          if (this.codeTime > 0) {
            this.codeTime--;
            this.codeTip = `${this.codeTime}S后重新获取`;
          } else {
            this.codeTime = 60;
            this.codeTip = "重新获取";
            clearInterval(this.interval);
          }
        }, 1000);
      } else {
        this.interval1 = setInterval(() => {
          if (this.codeTime1 > 0) {
            this.codeTime1--;
            this.codeTip1 = `${this.codeTime1}S后重新获取`;
          } else {
            this.codeTime1 = 60;
            this.codeTip1 = "重新获取";
            clearInterval(this.interval1);
          }
        }, 1000);
      }
    },
    getCodeFun(type) {
      if (!this.phone) {
        this.$alert("请先输入手机号");
        return;
      }
      if (
        type == 1 &&
        this.codeTip !== "获取验证码" &&
        this.codeTip !== "重新获取"
      )
        return;
      if (
        type == 2 &&
        this.codeTip1 !== "获取验证码" &&
        this.codeTip1 !== "重新获取"
      )
        return;
      getCode(this.phone).then((res) => {
        // this.code = res.data;
        this.code1 = res.data;
        this.codeCount(type);
      });
    },
    registerFun() {
      if (!this.passwordReg.test(this.password)) {
        this.$message.error("密码应为8-15位字母和数字组合");
        return;
      }
      let params = {
        phone: this.phone,
        companyName: this.comparename,
        userPass: this.password,
        remake: this.code1,
      };
      register(params).then((res) => {
        if (res.code == 200) {
          this.$message.success("注册成功");
          setTimeout(() => {
            this.tabsactive = true;
          }, 800);
        } else {
          this.$message.error(res.message);
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.content {
  display: flex;
}
.bg-1 {
  background-image: url("@/assets/img/login_bg.jpeg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-color: #fff;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
}
.card {
  position: absolute;
  top: 47%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 570px;
  height: 65%;
  padding-bottom: 40px;
  background-color: #fff;
  border-radius: 20px;
  // box-shadow: 0 0 10px #ccc;
  box-shadow: 0px 8px 6px 3px rgba(20, 7, 0, 0.34);
  display: flex;
  flex-direction: column;
  align-items: center;
  .companyname {
    margin-top: 50px;
    text-align: center;
    font-size: 36px;
  }
  .title {
    font-size: 20px;
    text-align: center;
    // letter-spacing: 10px;
  }
  .tabs {
    margin-top: 36px;
    font-size: 19px;
    width: 400px;
    display: flex;
    justify-content: space-around;
    .item {
      font-weight: 700;
      &:hover {
        cursor: pointer;
      }
    }
    .itemactive {
      &::after {
        content: "";
        display: block;
        width: 160%;
        height: 1px;
        transform: translateX(-20%);
        background-color: #d0161d;
        margin-top: 10px;
      }
    }
  }
  .form {
    transform: scale(1.1);
    margin-top: 10%;
    .item {
      box-sizing: border-box;
      width: 400px;
      height: 42px;
      border: 1px solid #dadada;
      display: flex;
      align-items: center;
      padding-left: 18px;
      border-radius: 1px;
      margin-bottom: 28px;
      .input {
        flex: 1;
        margin-left: 5px;
        border: none;
        outline: none;
      }
      .item-btn {
        width: 108px;
        height: 100%;
        line-height: 42px;
        background-color: #dddddd;
        text-align: center;
        font-size: 10px;
        white-space: nowrap;
      }
    }
    .btn {
      margin-top: 10px;
      width: 400px;
      height: 48px;
      color: #fff;
      text-align: center;
      font-size: 24px;
    }
    .text {
      margin-top: 25px;
      text-align: center;
      font-size: 14px;
      .span {
        color: #6590c0;
      }
    }
  }
  .suggest {
    font-size: 13px;
    margin-top: 15px;
  }
  .texts {
    margin-top: 20px;
    width: 300px;
    display: flex;
    justify-content: space-around;
    font-size: 16px;
    color: #62ace0;
  }
}
</style>
